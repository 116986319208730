import React from 'react'
import { FormattedMessage } from 'react-intl'
import Scrollspy from 'react-scrollspy'
import { flatDeep } from '../../utilities/utilities'

import streamIcon from './stream.svg'

interface ArticleHeader {
  url: string
  title: string
  items?: ArticleHeader[],
  level?: number
}

interface ArticleTOCProps {
  items: ArticleHeader[]
}

const ArticleTOC: React.FC<ArticleTOCProps> = ({items}) => {

  const flattenTOCHeaders = (articleHeaders: ArticleHeader[], level: number = 1): any[] => {
    return flatDeep(articleHeaders.map(ah => {
      if(ah.items) {
        return [
          {
            level,
            title: ah.title,
            url: ah.url,
          },
          ...flattenTOCHeaders(ah.items, level+1)
        ]
      } else {
        return {
          level,
          title: ah.title,
          url: ah.url,
        }
      }
    }), Infinity)
  }

  const listedItems = flattenTOCHeaders(items)

  return (
    <aside className="docs-toc">
      <div className="position-sticky">
        <h6 className="docs-toc__title"><img src={streamIcon} alt="Stream icon" /> <FormattedMessage id="articles.toc_title"/></h6>
        <Scrollspy
          className="docs-toc__list"
          items={
            listedItems
            .map((item: ArticleHeader) => item.url ? item.url.substring(1) : '')
          }
          currentClassName="docs-toc__link--current"
        >
          {
            listedItems.map((item: ArticleHeader) =>
              <li
                key={item.url}
                className={`docs-toc__link docs-toc__link--level-${item.level}`}
              >
                <a href={item.url}>{item.title}</a>
              </li>
            )
          }
        </Scrollspy>
      </div>
    </aside>
  )
}

export default ArticleTOC
