import React from 'react'

import './styles.scss'

interface AsideProps {
  children?: React.ReactNode
  title: string
  type?: 'note' | 'info' | 'danger'
}

const Aside: React.FC<AsideProps> = ({children, title, type}) => {
  const className = type || 'info'

  return (
    <aside className={`
      article-aside
      aside--${className}
    `}>
      {title && <h5>{title}</h5>}
      {children}
    </aside>
  )
}

export default Aside
