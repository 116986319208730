import React, { useState } from 'react';

import Highlight, { defaultProps } from 'prism-react-renderer';
import Prism from 'prism-react-renderer/prism';
import theme from 'prism-react-renderer/themes/nightOwl';
import CopyToClipboard from 'react-copy-to-clipboard';

import csharpLang from 'refractor/lang/csharp';
import rubyLang from 'refractor/lang/ruby';
import swiftLang from 'refractor/lang/swift';

csharpLang(Prism);
rubyLang(Prism);
swiftLang(Prism);

import './styles.scss'

interface CodeHighlightProps {
  language?: any;
  code: string;
}

const CodeHighlight: React.FC<CodeHighlightProps> = ({language, code}) => {
  const [codeCopied, setCodeCopied] = useState(false);

  const copyCode = () => {
    setCodeCopied(true)
    setTimeout(()=> setCodeCopied(false), 5000)
  }

  return (
    <div className="code-wrapper">
      <CopyToClipboard
        text={code}
        onCopy={() => copyCode() }
      >
        <button className={`copy-btn ${codeCopied && 'copy-btn--copied'}`}>{codeCopied ? 'Copiado!' : 'Copiar'}</button>
      </CopyToClipboard>
      <Highlight
        {...defaultProps}
        code={code}
        language={language ? language : ''}
        Prism={Prism}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.slice(0, -1).map((line, i) => (
              <div {...getLineProps({ line, key: i })} key={i}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} key={key} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}

export default CodeHighlight
