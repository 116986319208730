import React, { useContext, useEffect, useState } from 'react'
import TabsContext from '../../context/TabsContext'
import CodeHighlight from '../CodeHighlight/CodeHighlight'
import langDict from './langDict'
import './styles.scss'

interface CodeTab {
  code: string;
  lang: string;
  tabName: string;

}

interface Animation {
  direction: string | null;
  index: number | null
}

interface CodeTabsProps {
  children: React.ReactNode[],
  group?: string;
}

const animationsInitialState = {
  fadeIn: {
    direction: null,
    index: null,
  },
  fadeOut: {
    direction: null,
    index: null,
  }
}

const CodeTabs: React.FC<CodeTabsProps> = ({children, group}) => {
  const tabs: CodeTab[] = [];

  if(Array.isArray(children)) {
    children.map((child: any) => {
      const childrenProps = child.props.children.props;
      const className = childrenProps.className || ''
      const matches = className.match(/language-(?<lang>.*)/)

      const lang = matches.groups.lang;

      tabs.push({
        code: childrenProps.children,
        lang,
        tabName: langDict[lang],
      })
    });
  } else {
    const childrenProps = children.props.children.props;
    const className = childrenProps.className || ''
    const matches = className.match(/language-(?<lang>.*)/)

    const lang = matches.groups.lang;

    tabs.push({
      code: childrenProps.children,
      lang,
      tabName: langDict[lang],
    })
  }



  const tabsContext = useContext(TabsContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  useEffect(() => {
    if(group && tabsContext.preferredTabs[group]) {
      tabs.forEach((tab, index) => {
        if(tab.lang === tabsContext.preferredTabs[group]) {
          setSelectedTabIndex(index)
        }
      })
    }
  });

  let animations: {[key: string]: Animation} = animationsInitialState

  const selectTab = (tab: CodeTab, newIndex: number) : void => {
    if(newIndex !== selectedTabIndex) {
      animations.fadeIn.direction =  selectedTabIndex > newIndex ? 'left' : 'right';
      animations.fadeOut.direction =  selectedTabIndex > newIndex ? 'right' : 'left';

      animations.fadeIn.index = newIndex
      animations.fadeOut.index = selectedTabIndex

      setSelectedTabIndex(newIndex)
      tabsContext.setPreferredTab(tab.lang, group)

      setTimeout(()=> resetAnimation(), 400)
    }
  }

  const resetAnimation = () => {animations = {...animationsInitialState}}

  return (
    <div className="code-tabs">
      <ul className="code-tabs__wrapper">
        {
          tabs.map((tab, i) =>
            <li
              className={`code-tab ${selectedTabIndex === i && 'code-tab--selected'}`}
              key={`${tab.tabName}-${tab.lang}`}
              onClick={() => {
                selectTab(tab, i)
              }}
            >
              {tab.tabName}
            </li>
          )
        }
      </ul>

      <div className="code-tabs__content-wrapper">
        {
          tabs.map((tab, i) =>
            <div
              className={`
                code-tabs__content
                ${selectedTabIndex === i && 'code-tabs__content--selected'}
                ${animations.fadeIn.index === i && 'code-tabs__content--fadeIn'}
                ${animations.fadeOut.index === i && 'code-tabs__content--fadeOut'}
                ${animations.fadeIn.index === i && animations.fadeIn.direction}
                ${animations.fadeOut.index === i && animations.fadeOut.direction}
              `}
              key={tab.lang}
            >
              <CodeHighlight
                code={tab.code}
                language={tab.lang}
              />
            </div>
          )
        }
      </div>

    </div>
  )
}

export default CodeTabs
