import React from 'react'
import CountryContext from '../../context/CountryContext'
interface CountryContentProps {
  children?: React.ReactNode
  only?: string[]
  except?: string[]
}

const CountryContent: React.FC<CountryContentProps> = ({
  children,
  only,
  except
}) => (
  <CountryContext.Consumer>
    {country => {
      let display = false

      if(only && only.includes(country)) {
        display = true
      }

      if(except && !except.includes(country)) {
        display = true
      }

      return display ? children : <></>
    }}
  </CountryContext.Consumer>
)

export default CountryContent
