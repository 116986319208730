
import { MDXProvider } from '@mdx-js/react'
import { navigate, useLocation } from '@reach/router';
import * as queryString from 'query-string';

import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useContext, useEffect, useState } from 'react'
import TabsContext from '../../context/TabsContext'
import { stringToSlug } from '../../utilities/utilities'
import Aside from '../Aside/Aside'
import CodeHighlight from '../CodeHighlight/CodeHighlight'
import CodeTabs from '../CodeTabs/CodeTabs'
import CountryContent from '../CountryContent/CountryContent'
import GuideLink from '../GuideLink/GuideLink';
import IntroLink from '../IntroLink/IntroLink';
import Link from '../Link/Link'
import NextStep from '../NextStep/NextStep'
import ArticleTOC from './ArticleTOC'

import clockIcon from './clock.svg'

import './styles.scss'

const shortCodes = {
  Aside,
  CodeTabs,
  CountryContent,
  GuideLink,
  IntroLink,
  NextStep,
  a: (props: any) => {
    if(props.href.substring(0,1) === '/') {
      const { href, ...newProps } = props
      return <Link to={href} {...newProps} />
    } else {
      return <a {...props}/>
    }
  },
  pre: (props: any) => {
    const className = props.children.props.className || ''
    const matches = className.match(/language-(?<lang>.*)/)

    if(matches) {
      const lang = matches.groups.lang

      return <CodeHighlight code={props.children.props.children} language={lang} />
    } else {
      return <CodeHighlight code={props.children.props.children} language=""/>
    }
  },
}

interface ArticleProps {
  title: string
  subtitle: string
  contents: any
  country: string
  fullWidth?: boolean
  hero?: boolean
  heroImg?: string
  heroTextColor?: string
}

const Article: React.FC<ArticleProps> = ({
  title,
  subtitle,
  fullWidth,
  contents,
  hero,
  heroImg,
  heroTextColor,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  // const [animations, setAnimations] = useState<{[key: string]: Animation}>(animationsInitialState)
  const tabsContext = useContext(TabsContext)

  const location = useLocation()

  useEffect(() => {
    const qs = queryString.parse(location.search)
    if(qs.tab) {
      contents.forEach((content: any, index: number) => {
        if(content.tabTitle === qs.tab) {
          setSelectedTab(index)
        }
      })
    } else if(tabsContext.preferredTabs.mainTabs) {
      contents.forEach((content: any, index: number) => {
        if(content.tabTitle === tabsContext.preferredTabs.mainTabs) {
          setSelectedTab(index)
          navigate(`?tab=${tabsContext.preferredTabs.mainTabs}`)
        }
      })
    }
    // Start of Survicate (www.survicate.com) code
      if( undefined !== window ){
        (function(w) {
          var s = document.createElement(`script`);
          s.src = `https://survey.survicate.com/workspaces/06bf5ed6cc8cad7ea547f976c79dbcb6/web_surveys.js`;
          s.async = true;
          var e = document.getElementsByTagName(`script`)[0];
          e.parentNode.insertBefore(s, e);
        })(window);
      }
    // End of Survicate code
  }, []);

  const selectTab = (newIndex: number) : void => {
    if(newIndex !== selectedTab) {
      setSelectedTab(newIndex)

      navigate(`?tab=${contents[newIndex].tabTitle}`);

      tabsContext.setPreferredTab(contents[newIndex].tabTitle, 'mainTabs')
    }
  }

  return (
    <MDXProvider components={shortCodes}>
      {
        hero &&
        <div className="article-hero"
          style={{
            backgroundImage: `url(${heroImg})`,
            color: heroTextColor,
          }}
        >
          <div className="article-container">
            <header>
              <div className="article-hero-titles">
                <h1 id={stringToSlug(title)} className="mt-0">{title}</h1>
                <p className="lead">{subtitle}</p>
              </div>
            </header>
          </div>
        </div>
      }
      <div className="article-container">
        <article className="article">
          {
            !hero &&
            <header>
              <div className="article-titles">
                <div className="d-flex justify-content-between flex-column flex-lg-row">
                  <h1 id={stringToSlug(title)} className="mt-0 order-2 order-lg-1">{title}</h1>
                  <div className="article-date order-1 order-lg-2">
                    <img src={clockIcon} alt="clock" height="14px" width="auto" className="mr-2"/> {contents[selectedTab].updatedAt}
                  </div>
                </div>
                <p className="lead">{subtitle}</p>
              </div>
            </header>
          }

          {
            contents.length > 1 &&
            <div className="article-tabs">
              {
                contents.map((content: any, index: number) =>
                  <div
                    className={`
                      article-tab
                      ${index === selectedTab && 'article-tab--selected'}
                    `}
                    key={content.tabTitle}
                    onClick={() => selectTab(index)}
                  >
                    <span className="article-tab__title">
                      {content.tabTitle}
                    </span>
                  </div>
                )
              }
            </div>
          }
          {
            contents.length > 1
            ?
              <MDXRenderer>
                {contents[selectedTab].body.childMdx.body}
              </MDXRenderer>
            : <MDXRenderer>
                {contents[0].body.childMdx.body}
              </MDXRenderer>
          }
        </article>
        {
          !fullWidth &&
          contents[selectedTab].body.childMdx.tableOfContents.items &&
          <ArticleTOC items={contents[selectedTab].body.childMdx.tableOfContents.items} />
        }

      </div>
    </MDXProvider>
  )
}

export default Article
