import React from 'react'
import Link from '../Link/Link'

import './styles.scss'

interface NextStepProps {
  title: string
  children: React.ReactNode
  side?: 'left' | 'right'
  type?: 'default' | 'required' | 'recommended'
  link: string
}

const NextStep: React.FC<NextStepProps> = ({children, title, side, type, link}) => {
  const displayType = type || 'default'

  return (
    <Link
      className={`
        next-step
        ${side && `next-step--${side}`}
        next-step--${displayType}
      `}
      to={link}
    >
      <h5>{title}</h5>
      {children}
    </Link>
  )
}

export default NextStep
