const langDict: any = {
  'actionscript': 'ActionScript',
  'bash': 'Bash',
  'c': 'C',
  'clike': 'C',
  'coffeescript': 'CoffeeScript',
  'cpp': 'C++',
  'csharp': 'C#',
  'css': 'CSS',
  'css-extr': 'CSS',
  'diff': 'Diff',
  'dotnet': '.NET',
  'git': 'Git',
  'go': 'Go',
  'graphql': 'GraphQL',
  'handlebars': 'Handlebars',
  'java': 'Java',
  'javascript': 'Javascript',
  'json': 'JSON',
  'jsx': 'JSX',
  'less': 'Less',
  'makefile': 'Makefile',
  'markdown': 'Markdown',
  'markup': 'Markup',
  'objectivec': 'Objective-C',
  'ocaml': 'OCaml',
  'php': 'PHP',
  'python': 'Python',
  'reason': 'Reason',
  'ruby': 'Ruby',
  'sass': 'Sass',
  'scss': 'Scss',
  'sql': 'SQL',
  'stylus': 'Stylus',
  'swift': 'Swift',
  'tsx': 'TSX',
  'typescript': 'Typescript',
  'wasm': 'WebAssembly',
  'yaml': 'YAML',
}

export default langDict
