import React from 'react'
import Link from '../Link/Link'

import './styles.scss'

interface IntroLinkProps {
  icon: string
  title: string
  description: string
  link: string
}

const IntroLink: React.FC<IntroLinkProps> = ({icon, title, description, link}) => {
  return (
    <Link className="intro-link" to={link}>
      <img src={icon} alt={title} className="intro-link__icon" />
      <div className="intro-link__content">
        <h5 className="mt-0 mb-0 text-primary">{title}</h5>
        <p className="mb-0 mt-2 text-dark-gray-2">{description}</p>
      </div>
    </Link>
  )
}

export default IntroLink
